<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="12"
              >
                <v-select
                  persistent-placeholder
                  filled
                  v-model="selectedReportName"
                  :items="reports"
                  item-text="reportName"
                  item-value="reportName"
                  label="Reports"
                  dense
                >
                </v-select>
              </v-col>

            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                lg="6"
              >
                <v-select
                  :disabled="! selectedReport || !selectedReport.needsFrom"
                  persistent-placeholder
                  filled
                  v-model="datePresetId"
                  :items="datePresets"
                  item-text="title"
                  item-value="id"
                  label="Rango"
                  dense
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                lg="3"
              >
                <v-text-field
                  :disabled="! selectedReport || !selectedReport.needsFrom"
                  persistent-placeholder
                  filled
                  class="dateField"
                  v-model="startDate"
                  height="26px"
                  type="date"
                  required
                  label="Inicio"
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                lg="3"
              >
                <v-text-field
                  :disabled="! selectedReport || !selectedReport.needsTill"
                  persistent-placeholder
                  filled
                  class="dateField"
                  v-model="endDate"
                  height="26px"
                  type="date"
                  label="Final"
                  required
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="! selectedReportName"
            color="primary"
            @click="queryReport()"
            text
          >
            Generar report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import api from '../api'
import { saveAs } from 'file-saver'

export default {
  data () {
    return {
      valid: false,
      datePresets: [],
      datePresetId: null,
      startDate: null,
      endDate: null,
      reports: [],
      selectedReportName: '',
      selectedReport: false
    }
  },
  mounted () {
    this.datePresets = this.getDatePresets()
    this.datePresetId = 1
    this.reports = this.getReports()
  },
  computed: {
    projects () {
      // return this.$store.state.projects
      // do not exclude archived projects
      return []
    }

  },
  watch: {
    selectedReportName: function (reportName) {
      this.selectedReport = this.reports.find(item => item.reportName === reportName)
    },

    datePresetId: function (id) {
      const datePreset = this.datePresets.find(item => item.id === id)
      if (datePreset) {
        this.startDate = datePreset.startDate
        this.endDate = datePreset.endDate
      }
    }
  },
  methods: {
    /**
     * format date to ISO dateTime string ("yyyy-MM-dd")
     * elimnating the timezone offset
     * https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
     * @param {Date} date
     * @return {String} dateStr
    **/
    formatDateToLocalDateIsoStr (date) {
      // we need to get offset of requested date (no just todays offset!)
      const tzoffset = (new Date(date)).getTimezoneOffset() * 60000 // offset in milliseconds
      return (new Date(date - tzoffset)).toISOString().slice(0, -14)
    },
    /**
     * format date to ISO dateTime string ("yyyy-MM-ddThh:mm:ss")
     * elimnating the timezone offset
     * https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
     * @param {Date} date
     * @return {String} dateTimeStr
    */
    formatDateToLocalDateTimeIsoStr (date) {
      const tzoffset = (new Date()).getTimezoneOffset() * 60000 // offset in milliseconds
      return (new Date(date - tzoffset)).toISOString().slice(0, -5)
    },
    getReports () {
      api.getItems('reports')
        .then(data => {
          this.reports = data
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    queryReport () {
      const from = this.selectedReport.needsFrom ? this.startDate : null
      const till = this.selectedReport.needsTill ? this.endDate : null
      api.queryReport(this.selectedReportName, from, till)
        .then(data => {
          const output = this.prepareExport(data)
          const dateTimeStr = this.formatDateToLocalDateTimeIsoStr(new Date())
          const filename = 'qdb2_report_' + this.selectedReportName + '_' + dateTimeStr + '.csv'
          const blob = new Blob([output], { type: '"text/plain;charset=utf-8' })
          saveAs(blob, filename)
          this.$store.commit('alert',
            {
              show: true,
              text: 'Report guardado en carpeta de descargas',
              type: 'success'
            }
          )
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    prepareExport (data) {
      // pending
      return data
    },

    getDatePresets () {
      const today = new Date()
      const yesterday = new Date(new Date().setDate(today.getDate() - 1))

      const longAgo = new Date(2000, 0, 1) // month jan = 0
      const weekStart = new Date(new Date().setDate(today.getDate() - today.getDay()))
      const week1End = new Date(new Date().setDate(weekStart.getDate() - 1))
      const week1Start = new Date(new Date().setDate(week1End.getDate() - week1End.getDay()))

      const yearStart = new Date(today.getFullYear(), 0, 1) // month jan = 0
      const year1Start = new Date(today.getFullYear() - 1, 0, 1) // month jan = 0
      const year1End = new Date(today.getFullYear() - 1, 11, 31) // month jan = 0

      const quarterStart = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1) // month jan = 0
      const quarter1End = new Date(new Date().setDate(quarterStart.getDate() - 1))
      const quarter1Start = new Date(quarter1End.getFullYear(), Math.floor(quarter1End.getMonth() / 3) * 3, 1) // month jan = 0

      const monthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      const month1Start = today.getMonth() === 0 ? new Date(today.getFullYear() - 1, 11, 1) : new Date(today.getFullYear(), today.getMonth() - 1, 1)
      const month1End = new Date(new Date().setDate(monthStart.getDate() - 1))

      // formatDateToLocalDateIsoStr => from mixins
      console.log(this.formatDateToLocalDateIsoStr(today), this.formatDateToLocalDateIsoStr(monthStart), this.formatDateToLocalDateIsoStr(yearStart))
      return [
        {
          id: 1,
          title: 'Todos los tiempos',
          startDate: this.formatDateToLocalDateIsoStr(longAgo),
          endDate: this.formatDateToLocalDateIsoStr(today)
        },
        {
          id: 2,
          title: 'Hoy',
          startDate: this.formatDateToLocalDateIsoStr(today),
          endDate: this.formatDateToLocalDateIsoStr(today)
        },
        {
          id: 3,
          title: 'Ayer',
          startDate: this.formatDateToLocalDateIsoStr(yesterday),
          endDate: this.formatDateToLocalDateIsoStr(yesterday)
        },
        {
          id: 4,
          title: 'Semana actual',
          startDate: this.formatDateToLocalDateIsoStr(weekStart),
          endDate: this.formatDateToLocalDateIsoStr(today)
        },
        {
          id: 5,
          title: 'Semana pasada',
          startDate: this.formatDateToLocalDateIsoStr(week1Start),
          endDate: this.formatDateToLocalDateIsoStr(week1End)
        },
        {
          id: 6,
          title: 'Mes actual',
          startDate: this.formatDateToLocalDateIsoStr(monthStart),
          endDate: this.formatDateToLocalDateIsoStr(today)
        },
        {
          id: 7,
          title: 'Mes pasado',
          startDate: this.formatDateToLocalDateIsoStr(month1Start),
          endDate: this.formatDateToLocalDateIsoStr(month1End)
        },
        {
          id: 8,
          title: 'Trimestre actual',
          startDate: this.formatDateToLocalDateIsoStr(quarterStart),
          endDate: this.formatDateToLocalDateIsoStr(today)
        },
        {
          id: 9,
          title: 'Trimestre pasado',
          startDate: this.formatDateToLocalDateIsoStr(quarter1Start),
          endDate: this.formatDateToLocalDateIsoStr(quarter1End)
        },
        {
          id: 10,
          title: 'Año actual',
          startDate: this.formatDateToLocalDateIsoStr(yearStart),
          endDate: this.formatDateToLocalDateIsoStr(today)
        },
        {
          id: 11,
          title: 'Año pasado',
          startDate: this.formatDateToLocalDateIsoStr(year1Start),
          endDate: this.formatDateToLocalDateIsoStr(year1End)
        }
      ]
    }
  }
}
</script>
