<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="updateQuery()"
      fixed-header
      mobile-breakpoint="0"
      :value="selected"
      single-select
    >
        <template
          v-slot:[`body.prepend`]="{ headers }"
        >
        <tr
          class="tableFilter"
          py-3
        >
          <td
            v-for="(header,key) in headers"
            :key="key"
            :class="'pb-1 ' + header.class"
          >
            <v-text-field
              v-if="header.filterType === 'text'"
              v-model="header.filterValue"
              hide-details
              single-line
              dense
              clearable
              @keyup.esc="header.filterValue = ''"
            ></v-text-field>
            <v-select
              v-else-if="header.filterType === 'select'"
              flat
              hide-details
              dense
              v-model="header.filterValue"
              :items="header.selectList"
            ></v-select>
          </td>
        </tr>
      </template>
      <template
        v-slot:item="{ item, index }"
      >
        <tr
          :key="item.saleId"
          @click.stop="showItem(item)"
          :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
        >
          <td>{{ item.saleId }}</td>
          <td>
            <a
              :href="'#/clients/' + item.clientId + '/sales'"
              onclick="event.stopPropagation()"
            >{{ item.matchcode }}</a>
          </td>
          <td> {{ item.invoiceNo }} </td>
          <td class="nowrap"> {{ (item.invoiceDate || '').substring(0,10) }} </td>
          <td class="text-right"> {{ item.amount | toRounded(2) }} </td>
          <td> {{ item.paymentTerm }} </td>
          <td class="hideOnUnpaid"> {{ item.iban }} </td>
          <td class="hideOnUnpaid"> {{ item.directDebit }} </td>
          <td class="text-right"> {{ item.overdue }} </td>
          <!--
          <td class="text-right"> {{ item.paid }} </td>
          <td class="text-right"> {{ item.paidAmount | toRounded(2) }} </td>
          <td class="nowrap"> {{ (item.payDate || '').substring(0,10) }} </td>
          -->
          <td> {{ item.payComment }} </td>

        </tr>
      </template>
    </v-data-table>
    <ModalInvoice
      ref="sale"
      v-model="selectedItemId"
      :inlineItem="inlineItem"
      @refreshList="getItems()"
      :modalIsOpen.sync="modalIsOpen"
    >
    </ModalInvoice>
  </div>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'
import ModalInvoice from '@/components/ModalInvoice'

export default {
  mixins: [ItemsMixins],
  components: {
    ModalInvoice
  },
  data () {
    return {
      itemsName: 'invoices_unpaid_sepa',
      itemName: 'sale',
      inlineItem: true,
      modalIsOpen: false,
      headers: [
        {
          text: 'Pedido',
          value: 'saleId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Match',
          value: 'matchcode',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Fra.',
          value: 'invoiceNo',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Fecha',
          value: 'invoiceDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },

        {
          text: 'Importe',
          value: 'amount',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },

        {
          text: 'Forma pago',
          value: 'paymentTerm',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'IBAN',
          value: 'iban',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Remesa',
          value: 'directDebit',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Vencido',
          value: 'overdue',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        /*
        {
          text: 'Pagado',
          value: 'paid',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Imp. Pagado',
          value: 'paidAmount',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Fecha Pago',
          value: 'payDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        */
        {
          text: 'Comentarios',
          value: 'payComment',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        }
      ]
    }
  },
  props: {
    submenu: String
  },
  methods: {
    defaultOptions () {
      return {
        sortBy: ['overdue'], sortDesc: [false], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    },
    showItem (item) {
      console.log('showItem')
      this.modalIsOpen = true
      this.selectedItemId = item[this.itemName + 'Id']
    },
    closeModal () {
      this.modalIsOpen = false
    }
  }
}
</script>
