<template>
  <div>
    <v-card
      elevation="1"
      class='inlineItem my-2'
      tile
    >
      <v-card-actions
        class="py-0 px-5"
      >
        <span
          v-if="saleIds.length === 1"
        >
          1 factura seleccionada
        </span>
        <span
          v-else-if="saleIds.length > 1"
        >
          {{ saleIds.length }} facturas seleccionadas
        </span>
        <v-spacer></v-spacer>

        <v-btn
          :disabled="saleIds.length === 0"
          :loading="isSending"
          color="error"
          @click="sendReminders()"
          text
          title="Generar recordatorios de pago para las facturas elegidas"
        >
          Generar recordatorios de pago
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="updateQuery()"
      fixed-header
      mobile-breakpoint="0"
      :value="selected"
      single-select
      show-select
    >
        <template
          v-slot:[`body.prepend`]="{ headers }"
        >
        <tr
          class="tableFilter"
          py-3
        >
          <td
            v-for="(header,key) in headers"
            :key="key"
            :class="'pb-1 ' + header.class"
          >
            <v-simple-checkbox
              v-if="key === 0"
              :indeterminate="saleIds.length !== 0 && saleIds.length !== items.length"
              v-model=selectionToggle
              :ripple="false"
              @input="toogleSelection"
            ></v-simple-checkbox>
            <v-text-field
              v-if="header.filterType === 'text'"
              v-model="header.filterValue"
              hide-details
              single-line
              dense
              clearable
              @keyup.esc="header.filterValue = ''"
            ></v-text-field>
            <v-select
              v-else-if="header.filterType === 'select'"
              flat
              hide-details
              dense
              v-model="header.filterValue"
              :items="header.selectList"
            ></v-select>
          </td>
        </tr>
      </template>
      <template
        v-slot:item="{ item, index }"
      >
        <tr
          :key="item.saleId"
          @click.stop="showItem(item)"
          :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
        >
          <td>
            <v-simple-checkbox
              color="red"
              v-model="item.checkbox"
              :ripple="false"
              @input="markForReminder(item)"
            ></v-simple-checkbox>
          </td>
          <td>{{ item.saleId }}</td>
          <td>
            <a
              :href="'#/clients/' + item.clientId + '/sales'"
              onclick="event.stopPropagation()"
            >{{ item.matchcode }}</a>
          </td>
          <td> {{ item.invoiceNo }} </td>
          <td class="nowrap"> {{ (item.invoiceDate || '').substring(0,10) }} </td>
          <td class="text-right"> {{ item.amount | toRounded(2) }} </td>
          <td> {{ item.paymentTerm }} </td>
          <td class="text-right"> {{ item.overdue }} </td>
          <!--
          <td class="text-right"> {{ item.paid }} </td>
          <td class="text-right"> {{ item.paidAmount | toRounded(2) }} </td>
          <td class="nowrap"> {{ (item.payDate || '').substring(0,10) }} </td>
          -->
          <td> {{ item.payComment }} </td>

        </tr>
      </template>
    </v-data-table>
    <ModalInvoice
      ref="sale"
      v-model="selectedItemId"
      :inlineItem="inlineItem"
      @refreshList="getItems()"
      :modalIsOpen.sync="modalIsOpen"
    >
    </ModalInvoice>

  </div>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'
import ModalInvoice from '@/components/ModalInvoice'
import api from '../api'

export default {
  mixins: [ItemsMixins],
  components: {
    ModalInvoice
  },
  data () {
    return {
      itemsName: 'invoices_unpaid',
      itemName: 'sale',
      inlineItem: true,
      modalIsOpen: false,
      selectionToggle: undefined,
      saleIds: [],
      isSending: false,
      headers: [
        {
          text: 'Pedido',
          value: 'saleId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Match',
          value: 'matchcode',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Fra.',
          value: 'invoiceNo',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Fecha',
          value: 'invoiceDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },

        {
          text: 'Importe',
          value: 'amount',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },

        {
          text: 'Forma pago',
          value: 'paymentTerm',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Vencido',
          value: 'overdue',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        /*
        {
          text: 'Pagado',
          value: 'paid',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Imp. Pagado',
          value: 'paidAmount',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Fecha Pago',
          value: 'payDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        */
        {
          text: 'Comentarios',
          value: 'payComment',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        }
      ]
    }
  },
  methods: {
    defaultOptions () {
      return {
        sortBy: ['overdue'], sortDesc: [false], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    },
    showItem (item) {
      console.log('showItem')
      this.modalIsOpen = true
      this.selectedItemId = item[this.itemName + 'Id']
    },
    closeModal () {
      this.modalIsOpen = false
    },
    toogleSelection (v) {
      const saleIds = []
      if (v) {
        for (const item of this.items) {
          item.checkbox = true
          saleIds.push(item.saleId)
        }
      } else {
        for (const item of this.items) {
          item.checkbox = false
        }
      }
      this.saleIds = saleIds
    },
    markForReminder (item) {
      if (item.checkbox) {
        this.saleIds.push(item.saleId)
      } else {
        this.saleIds = this.saleIds.filter((v) => { return v !== item.saleId })
      }
    },
    sendReminders () {
      this.isSending = true
      api.generateReminders({ saleIds: this.saleIds })
        .then(() => {
          this.isSending = false
          // unselect after sending reminders
          this.saleIds = []
          this.getItems()
          this.$store.commit('alert',
            {
              show: true,
              text: 'Recordatorios creados y enviados a tu correo electrónico.',
              type: 'success'
            }
          )
        })
        .catch(e => {
          this.isSending = false
          this.$store.commit('alert',
            {
              show: true,
              text: e.message || 'Error al crear recordatorios!',
              type: 'error'
            }
          )
        })
    }
  }
}
</script>
