<template>
  <v-container
    fluid
    style="overflow: auto"
  >
    <v-row
      class="overflowLayout"
    >
      <v-col
        class="overflowFlex"
      >
        <v-tabs>
          <v-tabs-slider
            color="primary"
          ></v-tabs-slider>
          <v-tab
            router-link
            :to="'/more/invoices/unpaid'"
          >
            Facturas pendientes
          </v-tab>
          <v-tab
            v-if="invoiceDocSepa"
            router-link
            :to="'/more/invoices/unpaid_sepa'"
          >
            Recibos pendientes
          </v-tab>
          <v-tab
            router-link
            :to="'/more/invoices/all'"
          >
            Todas Facturas
          </v-tab>
        </v-tabs>
        <v-tabs-items>
          <component
            :is="moduleName"
          >
          <!-- component changes when vm.submenu changes! -->
          </component>
        </v-tabs-items>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InvoicesAll from '@/components/InvoicesAll'
import InvoicesUnpaid from '@/components/InvoicesUnpaid'
import InvoicesUnpaidSepa from '@/components/InvoicesUnpaidSepa'
export default {
  components: {
    InvoicesAll,
    InvoicesUnpaid,
    InvoicesUnpaidSepa
  },
  data () {
    return {
      invoiceDocSepa: this.$store.state.invoiceDocSepa || false
    }
  },
  props: {
    submenu: String
  },
  computed: {
    moduleName () {
      let moduleName = 'InvoicesAll'
      if (this.submenu === 'unpaid') {
        moduleName = 'InvoicesUnpaid'
      } else if (this.submenu === 'unpaid_sepa') {
        moduleName = 'InvoicesUnpaidSepa'
      }
      return moduleName
    }
  }
}
</script>
