<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="12"
                lg="4"
              >
                <v-autocomplete
                  dense
                  persistent-placeholder
                  filled
                  v-model="productId"
                  :rules="productIdRules"
                  :items="productsSelect"
                  item-text="productId"
                  item-value="productId"
                  label="Producto"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.productId"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.productName"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                lg="4"
              >
                <v-autocomplete
                  dense
                  persistent-placeholder
                  :disabled="! productId"
                  filled
                  v-model="palletId"
                  :rules="palletIdRules"
                  :items="palletIdsSelect"
                  item-text="palletId"
                  item-value="palletId"
                  no-data-text="No hubo compras de este producto"
                  label="Lote / PalletId"
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                lg="4"
              >
                <v-autocomplete
                  dense
                  :disabled="!productId || !palletId"
                  persistent-placeholder
                  filled
                  v-model="clientId"
                  :items="clientIdsSelect"
                  item-text="matchcode"
                  item-value="clientId"
                  no-data-text="No hubo ventas de este producto / palletId"
                  label="Cliente (opcional)"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.matchcode"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.company"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

            </v-row>

          </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="productId && palletId && clientIdsSelect.length > 0"
              text
              link
              target="_blank"
              :href="apiHost + '/api/traceabilityreport/' + productId + '/' + palletId + (clientId ? '?clientId=' + clientId : '')"
            >
              <v-icon left>
                {{ mdiFilePdfBox }}
              </v-icon>
              Abrir report
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import api from '../api'
import { mdiFilePdfBox } from '@mdi/js'

export default {
  data () {
    return {
      valid: false,
      mdiFilePdfBox: mdiFilePdfBox,
      apiHost: this.$store.state.apiHost,
      productId: null,
      productIdRules: [
        (v) => !!v || 'Falta elegir un producto'
      ],
      palletIdsSelect: [],
      palletId: null,
      palletIdRules: [
        (v) => !!v || 'Falta elegir un lote'
      ],
      clientIdsSelect: [],
      clientId: null
    }
  },
  computed: {
    productsSelect () {
      return this.$store.state.productsSelect
    }
  },
  watch: {
    productId: function (productId) {
      this.palletId = null
      this.clientId = null
      api.getTraceabilityPalletIds(productId)
        .then(data => {
          this.palletIdsSelect = data
        })
    },
    palletId: function (palletId) {
      this.clientId = null
      if (!palletId) {
        return
      }
      api.getTraceabilityClientIds(this.productId, palletId)
        .then(data => {
          this.clientIdsSelect = data
        })
    }
  }
}
</script>
