<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="800px"
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
      >
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="close"
          >
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ item.matchcode }} ({{ item.clientId }}), Fra. {{ item.invoiceNo }}, Importe: {{ item.amount | toRounded(2) }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text
        >
          <v-container
            fluid
            pa-2
          >
              <v-row
                dense
              >
                <v-col
                  cols="6"
                  sm="4"
                >
                  <v-select
                    dense
                    persistent-placeholder
                    filled
                    v-model="item.paid"
                    label="Pagado"
                    :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                  ></v-select>
                </v-col>

                <v-col
                  cols="6"
                  sm="4"
                >
                  <v-text-field
                    dense
                    persistent-placeholder
                    filled
                    reverse
                    type="number"
                    v-model="item.paidAmount"
                    label="Importe"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    dense
                    persistent-placeholder
                    filled
                    height="26px"
                    type="date"
                    v-model="item.payDate"
                    label="Fecha pago"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                dense
              >
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-textarea
                    dense
                    persistent-placeholder
                    filled
                    auto-grow
                    rows="3"
                    v-model="item.payComment"
                    label="Comentarios cobro"
                  ></v-textarea>
                </v-col>
              </v-row>
          </v-container>
          <!-- footer -->
          <div
            class="text-caption"
          >
            <span>
              Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
            </span>
            <span v-if="item.recChanger">
              Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
            </span>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
            text
            title="Cerrar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ItemMixins from '@/utils/ItemMixins'
import { mdiClose } from '@mdi/js'

export default {
  mixins: [ItemMixins],
  data () {
    return {
      mdiClose: mdiClose,
      editRight: 'CAN_INVOICE',
      itemName: 'sale', // needed for ItemMixins
      itemsName: 'invoices' // needed for ItemMixins
    }
  },
  props: {
    // value represents selectedItemId
    value: [String, Number],
    inlineItem: Boolean,
    // bidirectional for dlg open status
    modalIsOpen: Boolean
  },
  watch: {
    'item.paid': function (val) {
      if (val === 1) {
        this.item.payDate = new Date().toISOString().slice(0, -14)
        this.item.paidAmount = this.item.amount
      } else if (val === 0) {
        this.item.payDate = null
        this.item.paidAmount = 0
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.modalIsOpen
      },
      set (value) {
        console.log('modalIsOpen', value)
        this.$emit('update:modalIsOpen', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
